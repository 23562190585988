<template>
  <b-modal
    id="form__data-entries-modal"
    :visible="visible"
    hide-footer
    centered
    no-fade
    @close="$emit('close')"
  >
    <div class="modal__body tw-p-8">
      <div class="modal__header mb-2">
        <h4 class="mb-1 modal__heading">
          {{ $t('Data Entries') }}
        </h4>
        <hr class="my-0">
      </div>
      <div>
        <b-table
          ref="refTable"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
        >
          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(tableKey)="{ item }">
            {{ $t($case.title(item.tableKey)) }}
          </template>

          <template #cell(rowName)="{ item }">
            <ProzessResourceName
              :has-permission="$can('Read', getPermissionName(item.tableKey)) && !item.deleted"
              :to="
                formatRouterLink({
                  schemaName: item.schemaKey,
                  tableKey: item.tableKey,
                  uuidKey: item.uuidKey,
                })
              "
              :title="
                item.deleted
                  ? $t('(Deleted Entity)')
                  : item.rowName || item.uuidKey
              "
            />
          </template>
        </b-table>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { formatRouterLink, getPermissionName } from '@/helpers/customEntity'

export default {
  props: {
    visible: Boolean,

    submission: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      tableColumns: [
        {
          key: 'tableKey',
          label: 'Entity Name',
          class: 'disabled',
        },
        {
          key: 'rowName',
          label: 'Title Field',
          class: 'disabled',
        },
      ],
    }
  },

  computed: {
    items() {
      return this.submission?.linkedEntities || []
    },
  },

  methods: {
    formatRouterLink,

    getPermissionName,
  },
}
</script>
