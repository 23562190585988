var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"form__data-entries-modal","visible":_vm.visible,"hide-footer":"","centered":"","no-fade":""},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal__body tw-p-8"},[_c('div',{staticClass:"modal__header mb-2"},[_c('h4',{staticClass:"mb-1 modal__heading"},[_vm._v(" "+_vm._s(_vm.$t('Data Entries'))+" ")]),_c('hr',{staticClass:"my-0"})]),_c('div',[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found')},scopedSlots:_vm._u([{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(tableKey)",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(_vm.$case.title(item.tableKey)))+" ")]}},{key:"cell(rowName)",fn:function({ item }){return [_c('ProzessResourceName',{attrs:{"has-permission":_vm.$can('Read', _vm.getPermissionName(item.tableKey)) && !item.deleted,"to":_vm.formatRouterLink({
                schemaName: item.schemaKey,
                tableKey: item.tableKey,
                uuidKey: item.uuidKey,
              }),"title":item.deleted
                ? _vm.$t('(Deleted Entity)')
                : item.rowName || item.uuidKey}})]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }