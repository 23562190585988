import API from '@axios'
import { stringify } from 'query-string'

const API_URL = '/api/forms'

export default {
  getAll(query = { size: 25 }) {
    return API.get(`${API_URL}/form?${stringify(query)}`)
  },

  getEntities() {
    return API.get(`${API_URL}/entities`)
  },

  create(payload) {
    return API.post(`${API_URL}/form`, payload)
  },

  update(id, payload) {
    return API.put(`${API_URL}/form/${id}`, payload)
  },

  delete(id, query = {}) {
    return API.delete(`${API_URL}/form/${id}?${stringify(query)}`)
  },

  getOne(id, query = {}) {
    return API.get(`${API_URL}/form/${id}?${stringify(query)}`)
  },

  getSubmissions(id, query = {}) {
    return API.get(`${API_URL}/data/${id}?${stringify(query)}`)
  },
}
