<template>
  <b-card id="page--advanced-search" no-body>
    <div class="m-2">
      <div>
        <b-table
          ref="refTable"
          :busy="loading"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>

          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(timestampSubmitted)="{ item }">
            {{ item.timestampSubmitted | moment }}
          </template>

          <template #cell(linkedEntities)="{ item }">
            <div class="line-clamp-1">
              {{
                formatDataEntries(item)
                  .map(
                    ({ key, value, deleted }) =>
                      `${$t(key)}: ${deleted ? $t('(Deleted Entity)') : value}`
                  )
                  .join('; ')
              }}
            </div>
          </template>
          <template #cell(actions)="{ item }">
            <b-badge
              pill
              variant="outline-secondary"
              class="tw-cursor-pointer tw-px-4"
              @click="handleShowAllClick(item)"
            >
              {{ $t('Show All') }}
            </b-badge>
          </template>
        </b-table>
      </div>
      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
    <FormDataEntriesModal
      :submission="submission"
      :visible="showDataEntriesModal"
      @close="showDataEntriesModal = false"
    />
  </b-card>
</template>

<script>
import formService from '@/services/form'
import listMixinFactory from '@/mixins/list'
import momentFilterMixin from '@/mixins/momentFilter'
import FormDataEntriesModal from '@/components/Forms/FormDataEntriesModal.vue'

export default {
  components: {
    FormDataEntriesModal,
  },

  mixins: [
    momentFilterMixin,
    listMixinFactory({
      routeName: 'forms.submissions',
      searchField: 'timestampSubmitted',
      service: formService,

      tableColumns: [
        {
          key: 'timestampSubmitted',
          label: 'Submitted Date / Time',
          class: 'disabled',
        },
        { key: 'linkedEntities', label: 'Data Entries', class: 'disabled' },
        { key: 'actions', class: 'text-center disabled' },
      ],

      sort: {
        timestampSubmitted: 'timestampSubmitted',
      },
    }),
  ],

  data() {
    return {
      showDataEntriesModal: false,
      submission: null,
    }
  },

  computed: {
    formId() {
      return this.$route.params.id
    },
  },

  methods: {
    formatDataEntries(item) {
      return item.linkedEntities.map(entity => {
        const tableName = this.$case.title(entity.tableKey)
        return {
          key: tableName,
          value: entity.rowName || entity.uuidKey,
          deleted: entity.deleted,
        }
      })
    },

    async getAllMethod(query) {
      return formService.getSubmissions(this.formId, query)
    },

    handleShowAllClick(item) {
      this.submission = item
      this.showDataEntriesModal = !this.showDataEntriesModal
    },

    routeWatcher() {
      this.fetchList()
    },
  },
}
</script>

<style></style>
